<template>
  <div
    class="h-dvh bg-white dark:bg-secondary-900"
  >
    <main class="px-10">
      <slot />
    </main>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  enforceLightMode: {
    type: Boolean,
    default: false,
  },
})

const darkModeEnabled = ref(false)

onMounted(() => {
  if (props.enforceLightMode) {
    if (document.querySelector('html')?.classList.contains('ghost-dark')) {
      darkModeEnabled.value = true
      document.querySelector('html')?.classList.remove('ghost-dark')
      document.querySelector('html')?.classList.add('ghost-light')
    }
  }
})

onUnmounted(() => {
  if (props.enforceLightMode && darkModeEnabled.value) {
    document.querySelector('html')?.classList.remove('ghost-light')
    document.querySelector('html')?.classList.add('ghost-dark')
  }
})
</script>
